import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoversService {
  constructor(private httpClient: HttpClient) {}

  getCovers(id: number): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + '/covercolors/stockWithImage/' + id)
      .pipe(
        map((response) => response),
        pluck('data')
      );
  }
}
