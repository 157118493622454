import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {
  $appStore: Observable<AppState>;
  $title: Observable<string> = null;
  $loading: Observable<boolean> = null;
  test: string;
  $uploaded: Observable<any>;
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.test = 'https://e.issuu.com/anonymous-embed.html?u=prahesta&d=wedding_1';
    this.$title   = this.store.select('appStore').pipe(map(store => store.title));
    this.$loading   = this.store.select('appStore').pipe(map(store => store.loading));
    this.$uploaded = this.store.select('appStore').pipe(map(store => store.uploaded));
  }


  goBack() {
    this.router.navigate(['/home']);
  }

  onLogout() {
    this.authService.removAuth();
    this.router.navigate(['/signin']);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onClickDone() {
    this.router.navigate(['/home']);
  }
}
