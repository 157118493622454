import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as AppActions from 'src/app/actions/app.action';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { AppState } from '../reducers/app.reducer';
import { AuthService } from './auth.service';

export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<{ appStore: AppState }>,
    private authService: AuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = JSON.parse(<string>sessionStorage.getItem('token'));
    // console.log(token)
    if (token) {
      if (!request.headers.has('Authorization')) {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json'),
          });
        }
        Promise.resolve(null).then(() =>
          this.store.dispatch(new AppActions.Setloading(true))
        );
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
        });
      }
    }
    return next.handle(request).pipe(
      finalize(() => this.store.dispatch(new AppActions.Setloading(false)))
      // loading menjadi tidak aktif setelah proses ambil data selesai
    );
  }
}
