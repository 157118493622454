import { Component, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import * as AppActions from 'src/app/actions/app.action';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { CoreService } from '../core.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from './../../../environments/environment';
import { shortMonths, months, statusGroups } from './../../shared/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { interval, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SurveyComponent } from '../survey/survey.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  order: any;
  PackageForm: FormGroup;
  packageorders: any;
  isLoadingSubmit: boolean;
  errors: any[];
  surveyMonkeyUrl: SafeResourceUrl;
  hasUpload = false;
  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService,
    private router: Router,
    private store: Store<{ appStore: AppState }>,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    this.store.dispatch(new AppActions.SetTitle(null));
    this.store.dispatch(new AppActions.SetUploaded(false));
  }

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.order = this.route.snapshot.data['order'];
    this.packageorders = this.order.package_orders;
    this.createForm();
    this.statusGroup();

    // cek status sudah pernah selesai upload
    const status =
      this.order.package_orders[0].photobooks[0].photobook_statuses;

    this.hasUpload = status.find((el) => el.status_type_id === 3)
      ? true
      : false;
  }

  onClickRule() {
    this.router.navigate(['/rules']);
  }
  createPagesArray() {
    for (let index = 0; index < this.order.package_orders.length; index++) {
      const packageOrder = this.order.package_orders[index];
      const pagesArray = [];
      for (let i = 1; i <= packageOrder.product.pages; i++) {
        pagesArray.push(i);
      }
      this.order.package_orders[index].product.pagesArray = pagesArray;
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  createForm(): any {
    this.PackageForm = new FormGroup({
      package_order: new FormArray([]),
    });
    // insert package order
    for (let index = 0; index < this.order.package_orders.length; index++) {
      const packageOrder = this.order.package_orders[index];
      const packageOrderForm = new FormGroup({
        id: new FormControl(packageOrder.id),
        photobooks: new FormArray([]),
      });

      (<FormArray>this.PackageForm.get('package_order')).push(packageOrderForm);
      // insert photobook to package form
      for (let j = 0; j < packageOrder.photobooks.length; j++) {
        const photobook = packageOrder.photobooks[j];
        const photosUploaded = photobook.photos.filter(
          (photo) => photo.db_path.indexOf('custome-cover') === -1
        );
        photobook.uploaded = photosUploaded.length;
        let formPhotobook = null;
        if (
          packageOrder.product.max_photo === null ||
          packageOrder.product.max_photo === 0
        ) {
          // tidak perlu upload foto
          formPhotobook = new FormGroup({
            id: new FormControl(photobook.id),
            layout_id: new FormControl(photobook.layout_id),
            cover_color_id: new FormControl(photobook.cover_color_id),
            title: new FormControl(photobook.title),
            caption: new FormArray([]),
            emblem_id: new FormControl(photobook.emblem_id),
          });
        } else {
          formPhotobook = new FormGroup({
            id: new FormControl(photobook.id, [Validators.required]),
            layout_id: new FormControl(photobook.layout_id, [
              Validators.required,
            ]),
            cover_color_id: new FormControl(photobook.cover_color_id, [
              Validators.required,
            ]),
            title: new FormControl(photobook.title, [
              Validators.required,
              Validators.pattern(/^[\x20-\x7F]+$/),
            ]),
            caption: new FormArray([]),
            emblem_id: new FormControl(photobook.emblem_id),
          });
          if (!packageOrder.product.is_album) {
            formPhotobook.get('layout_id').setValidators([]);
            formPhotobook.get('layout_id').updateValueAndValidity();

            formPhotobook.get('cover_color_id').setValidators([]);
            formPhotobook.get('cover_color_id').updateValueAndValidity();

            formPhotobook.get('title').setValidators([]);
            formPhotobook.get('title').updateValueAndValidity();

            formPhotobook.get('emblem_id').setValidators([]);
            formPhotobook.get('emblem_id').updateValueAndValidity();
          }

          if (!packageOrder.product.is_with_cover) {
            formPhotobook.get('cover_color_id').setValidators([]);
            formPhotobook.get('cover_color_id').updateValueAndValidity();
          }
        }
        (<FormArray>(
          (<FormGroup>(
            (<FormArray>this.PackageForm.controls['package_order']).controls[
              index
            ]
          )).controls['photobooks']
        )).push(formPhotobook);
      }
    }
  }

  onSelectChangeOrder(
    indexPackageOrder: number,
    indexPhotoobok: number,
    indexCaption: number,
    event: any
  ) {
    // tslint:disable-next-line:max-line-length
    const photobooks = (<FormArray>(
      (<FormArray>this.PackageForm.get('package_order')).controls[
        indexPackageOrder
      ].get('photobooks')
    )).controls[indexPhotoobok].value;
    const captions = photobooks.caption.filter(
      (capt) => capt.page === event.value
    );
    if (captions.length > 0) {
      for (let index = 0; index < photobooks.caption.length; index++) {
        const caption = photobooks.caption[index];
        if (index !== indexCaption && caption.page === event.value) {
          // tslint:disable-next-line:max-line-length
          (<FormArray>(
            (<FormArray>(
              (<FormArray>this.PackageForm.get('package_order')).controls[
                indexPackageOrder
              ].get('photobooks')
            )).controls[indexPhotoobok].get('caption')
          )).controls[index]
            .get('page')
            .setValue(null);
        }
      }
    }
    // console.log(photobooks);
  }

  isCompletePhoto(photos: number, max_photo: number) {
    if (photos < max_photo) {
      return 'warn';
    }
    return 'accent';
  }

  get packageOrders() {
    return <FormArray>this.PackageForm.get('package_order');
  }

  cloneCaption(indexPackageOrder: number, indexPhotobook: number) {
    (<FormArray>(
      (<FormArray>(
        (<FormArray>this.PackageForm.get('package_order')).controls[
          indexPackageOrder
        ].get('photobooks')
      )).controls[indexPhotobook].get('caption')
    )).push(
      new FormGroup({
        note: new FormControl(null, [Validators.required]),
        page: new FormControl(null, [
          Validators.required,
          Validators.pattern('^[0-9]+$'),
        ]),
      })
    );
  }

  onDeletCaption(
    indexPackageOrder: number,
    indexPhotobook: number,
    indexCaption: number
  ) {
    (<FormArray>(
      (<FormArray>(
        (<FormArray>this.PackageForm.get('package_order')).controls[
          indexPackageOrder
        ].get('photobooks')
      )).controls[indexPhotobook].get('caption')
    )).removeAt(indexCaption);
  }

  onChangeTheme(photobook: any) {
    const notes = [];
    photobook.caption.forEach((caption) => {
      if (caption.note && caption.page) {
        notes.push(`${caption.note}-${caption.page}`);
      }
    });
    photobook.notes = notes.join();
    this.coreService.updatePhotobook(photobook).subscribe((data) => {
      this.router.navigate(['/layouts/', photobook.id]);
    });
  }

  onChangeEmblem(photobook: any) {
    const notes = [];
    photobook.caption.forEach((caption) => {
      if (caption.note && caption.page) {
        notes.push(`${caption.note}-${caption.page}`);
      }
    });
    photobook.notes = notes.join();
    this.coreService.updatePhotobook(photobook).subscribe((data) => {
      this.router.navigate(['/emblem/', photobook.id]);
    });
  }

  onChangeCover(photobook: any) {
    const notes = [];
    photobook.caption.forEach((caption) => {
      if (caption.note && caption.page) {
        notes.push(`${caption.note}-${caption.page}`);
      }
    });
    photobook.notes = notes.join();
    this.coreService.updatePhotobook(photobook).subscribe((data) => {
      this.router.navigate(['/covers/', photobook.id]);
    });
  }

  showWarning(message: string) {
    const snackBarRef = this.snackBar.open(message, 'Tutup', {
      duration: 5000,
    });
    // snackBarRef.afterDismissed().subscribe(() => {
    //   console.log('The snack-bar was dismissed');
    // });
  }

  onUpload(photobook: any, indexPackageOrder: number, indexPhotobook: number) {
    const photobookForm = (<FormArray>(
      (<FormArray>this.PackageForm.get('package_order')).controls[
        indexPackageOrder
      ].get('photobooks')
    )).controls[indexPhotobook].get('title');
    if (photobookForm.hasError('required')) {
      this.showWarning('Silahkan Lengkapi dulu Judul');
    } else if (photobookForm.hasError('pattern')) {
      this.showWarning('Ada karakter yang tidak didukung');
    } else {
      this.coreService.updatePhotobook(photobook).subscribe((data) => {
        this.router.navigate(['/upload/', photobook.id]);
      });
    }
  }

  onLogout() {
    this.authService.removAuth();
    this.router.navigate(['/signin']);
  }

  isFotoComplete() {
    const photoStatutes = [];
    this.errors = [];
    this.order.package_orders.forEach((package_order) => {
      package_order.photobooks.forEach((photobook) => {
        let maxPhoto = package_order.product.max_photo;

        if (package_order.product.is_cover_custome) {
          maxPhoto += 1;
        }
        if (photobook.photos.length === maxPhoto) {
          photoStatutes.push(true);
        } else if (photobook.photos.length > maxPhoto) {
          photoStatutes.push(false);
          this.errors.push({
            message: `Foto album ${package_order.product.name} ${photobook.title} upload melebihi max foto !! `,
          });
        } else {
          photoStatutes.push(false);
          this.errors.push({
            message: `Foto album ${package_order.product.name} ${photobook.title} belum lengkap !! `,
          });
        }
      });
    });
    return photoStatutes;
  }

  getPath() {
    const date = new Date(this.order.created_at);
    const dateString = `${date.getDate()}-${
      months[date.getMonth()]
    }-${date.getFullYear()}`;
    const csName = this.order.customer_service
      ? this.order.customer_service.full_name
      : 'selfservice';
    // tslint:disable-next-line:max-line-length
    const dropboxPaymentPath = `https://www.dropbox.com/home/Photostory%20Indonesia${
      environment.dropboxPath
    }/${dateString}/(CS-${csName})-${
      this.order.order_code
    }-${this.order.customer.name.replace(/\s/g, '_')}`;
    return dropboxPaymentPath;
  }

  getPhotobookFromOrder(packageOrders: any) {
    const photobooks = [];
    for (let index = 0; index < packageOrders.length; index++) {
      const packageOrder = packageOrders[index];
      for (
        let indexPhotobook = 0;
        indexPhotobook < packageOrder.photobooks.length;
        indexPhotobook++
      ) {
        const photobook = packageOrder.photobooks[indexPhotobook];
        photobooks.push(photobook);
      }
    }
    return photobooks;
  }

  async onFormSubmit() {
    const checkPhoto = this.isFotoComplete();
    if (this.PackageForm.valid) {
      if (!checkPhoto.includes(false)) {
        const order = this.PackageForm.value;
        order.id = this.order.id;
        order.link_photo = this.getPath();
        const payload = {
          desc: 'Selesai Upload dari Web Kirim Foto',
          photobook_id: this.order.package_orders[0].photobooks[0].id,
          next_status: 3,
          current_status:
            this.order.package_orders[0].photobooks[0].current_status,
        };
        this.isLoadingSubmit = true;
        this.coreService.forwardFlow(payload).subscribe(
          () => {
            this.coreService.update(order).subscribe(
              () => {
                this.coreService.sendNotif(this.order.order_code).subscribe();
                const dialogRef = this.dialog.open(SurveyComponent, {
                  disableClose: true,
                });
                dialogRef.afterClosed().subscribe((result) => {
                  location.reload();
                });
              },
              (error) => {
                this.router.navigate(['/home']);
                this.isLoadingSubmit = false;
              }
            );
            // this.coreService.sendNotif(order.id).subscribe(() => { });
          },
          (error) => {
            this.isLoadingSubmit = false;
            const customError =
              error.error && error.error.message ? error.error.message : '';
            if (customError.includes('Stok Cover')) {
              this.showWarning(`${error.error.message}`);
            } else {
              this.showWarning(
                'Gagal update foto, silahkan ulangi kembali atau hubungi CS'
              );
            }
          }
        );
      }
    } else {
      this.showWarning('Silahkan Lengkapi dulu Judul, cover, dan Layout');
    }
  }

  statusGrouping(photobook: any) {
    const input = photobook.photobook_statuses;
    return statusGroups.reduce((acc, val) => {
      const exist = input.find((el) => val.value.includes(el.status_type_id));
      if (exist) {
        acc.push({
          text: val.text,
          created_at: exist.created_at,
        });
      }
      return acc;
    }, []);
  }

  statusGroup() {
    if (this.order) {
      this.order.package_orders = this.order.package_orders.map((el) => {
        el.photobooks = el.photobooks.map((idx) => {
          idx.statusGrouping = this.statusGrouping(idx);
          return idx;
        });
        return el;
      });
    }
  }
}
