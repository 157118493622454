<div class="photobook" fxLayout="row"  fxLayoutAlign="flex-start start">

    <div class="album" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <div class="order" fxLayout="column" fxLayoutAlign="center center">
            <div class="order__code" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                <span>Produk</span>
                <span>{{ photobook?.package_order?.product?.name }}</span>
            </div>
            <div class="order__create" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                <span>Judul Album</span>
                <span>{{ photobook?.title }}</span>
            </div>
    
            <div class="order__status" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                <span>Layout</span>
                <span>{{ photobook?.layout?.name }}</span>
            </div>
        </div>
    </div>

    <div class="block" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let layout of layouts; let i = index">
        <h4>{{ layout?.name }}</h4>
        <div class="block__image" fxLayout="column" fxLayoutAlign="center start">
            <iframe fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" class="frame" *ngIf="layout.html_file;else noImage" [src]="sanitize(layout.html_file)" style="border:none;width:100%;" 
                class="embed-responsive-item iframe-custom" allowfullscreen>
            </iframe>
            
        </div>
        <div class="block__button">
            <button mat-raised-button color="accent"  fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" (click)="onChooseTheme(layout)">
                Pilih
            </button>
        </div>
    </div>
  
  </div>

<ng-template #noImage>
    <div class="default">
        <img src="assets/no-image.png" alt="">
    </div>
</ng-template>

<!-- <iframe class="frame" [src]="sanitize(test)" style="border:none;width:100%;" 
class="embed-responsive-item iframe-custom" allowfullscreen>
</iframe> -->