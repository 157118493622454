import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map, pluck } from 'rxjs/operators';

@Injectable()
export class ColorResolver implements Resolve<any> {

  constructor(
    private httpClient: HttpClient
  ) { }
  resolve() {
    return this.httpClient.get(environment.apiUrl + '/covercolors/published')
    .pipe(
      map(response => response),
      pluck('data')
    );
  }

}