import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { CoreService } from '../core/core.service';

@Injectable()
export class PhotobookResolver implements Resolve<any> {
  constructor(private coreService: CoreService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const photobookId = route.params['photobook_id'];
    return this.coreService.getPhotobookById(photobookId);
  }
}
