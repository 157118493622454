import * as PhotobookListActions from 'src/app/actions/photobook.action';
import { Photobook } from '../interface/photobook';

export interface PhotobookState {
    photobooks: Photobook[];
}

const initialState = {
    photobooks: []
};


export function photobookReducer(state = initialState, action: PhotobookListActions.PhotobookListActions) {
    switch (action.type) {
        case PhotobookListActions.ADD_PHOTOBOOK:
            return {
                ...state,
                photobooks: [...state.photobooks, action.payload]
            };

        case PhotobookListActions.REMOVE_PHOTOBOOK:
                return {
                    ...state,
                    photobooks: []
                };
        default:
            return state;
    }
    return state;
}

