import { Action } from '@ngrx/store';
import { Photobook } from '../interface/photobook';
export const ADD_PHOTOBOOK = 'ADD_PHOTOBOOK';
export const REMOVE_PHOTOBOOK = 'REMOVE_PHOTOBOOK';
export const EDIT_COVER = 'EDIT_COVER';


export class AddPhotobook implements Action {
    readonly type = ADD_PHOTOBOOK;
    constructor(public payload: Photobook) {}
}

export class RemovePhotobook implements Action {
    readonly type = REMOVE_PHOTOBOOK;
    constructor(public payload: number) {}
}

export class EditCover  implements Action {
    readonly type = EDIT_COVER;
    constructor(public payload: Photobook) {}
}



export type PhotobookListActions = AddPhotobook | RemovePhotobook | EditCover;
