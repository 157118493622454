import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import { map, filter } from 'rxjs/operators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AlertDialogComponent } from './core/alert-dialog/alert-dialog.component';
import { CoreService } from './core/core.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'new-uploader';
  $error: any;

  constructor(
    private store: Store<{ appStore: AppState }>,
    public dialog: MatDialog,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    // this.$error = this.store.select('appStore').pipe(map(store => store.error));
  }

}
