import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as AppActions from 'src/app/actions/app.action';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import { UploadService } from './upload.service';
import { map, retryWhen } from 'rxjs/operators';
import { DropboxService } from './dropbox.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { months } from './../../shared/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from './../../../environments/environment';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { unlimitedRetry } from 'src/app/shared/retry-backoff';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, AfterViewInit {
  @ViewChild('uploadInput', { static: false })
  uploadInput: ElementRef;
  photobook: any;
  product: any;
  covers: any;
  parentLoading = false;
  photos: any;
  allPhotos: any;
  coverPhoto = null;
  dropboxPath: any;
  defaultImage = '/assets/loading.gif';
  dropboxPaymentPath: string;
  checkAll = false;
  customeCover = false;
  maxPhoto: any;
  $uploaded: any;
  isToSaveOrder = false;
  checkPhoto = false;
  photobookForm: FormGroup;
  loading = false;
  coverPhotoUploaded = false;
  photosToSave: any[];
  isFirst = true;
  isAlbum: boolean;
  max_caption: number;

  constructor(
    private route: ActivatedRoute,
    private store: Store<{ appStore: AppState }>,
    private uploadService: UploadService,
    private dropboxService: DropboxService,
    private _sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.store.dispatch(new AppActions.SetTitle('Upload'));
  }

  ngOnInit() {
    this.$uploaded = this.store
      .select('appStore')
      .pipe(map((store) => store.uploaded));
    this.photobook = this.route.snapshot.data['photobook'];

    this.isAlbum = this.photobook.package_order.product.is_album;
    this.maxPhoto = this.photobook.package_order.product.max_photo;
    this.max_caption = this.photobook.package_order.product.max_caption;
    this.customeCover = this.photobook.package_order.product.is_cover_custome;
    this.getPhotosBatch();
  }

  ngAfterViewInit() {}

  isUploadedDone() {
    this.maxPhoto = this.photobook.package_order.product.max_photo;
    if (this.photos.length >= this.maxPhoto) {
      this.store.dispatch(new AppActions.SetUploaded(true));
    } else {
      this.store.dispatch(new AppActions.SetUploaded(false));
    }
  }

  onClickClose() {
    this.router.navigate(['/home']);
  }

  getPath() {
    const date = new Date(this.photobook.package_order.order.created_at);
    const dateString = `${date.getDate()}-${
      months[date.getMonth()]
    }-${date.getFullYear()}`;
    // tslint:disable-next-line:max-line-length
    const csName = this.photobook.package_order.order.customer_service
      ? this.photobook.package_order.order.customer_service.full_name
      : 'selfservice';
    if (this.photos.length > 1) {
      const dbPathSample = this.photos[1].db_path.split('/');
      const nameFolder = dbPathSample[5].split('-');
      if (nameFolder.length === 1) {
        this.isFirst = true;
      } else {
        this.isFirst = false;
      }
    } else {
      this.isFirst = true;
    }
    this.dropboxPaymentPath = this.isFirst
      ? // tslint:disable-next-line:max-line-length
        `${environment.dropboxPath}/${dateString}/(CS-${csName})-${
          this.photobook.package_order.order.order_code
        }-${this.photobook.package_order.order.customer.name.replace(
          /\s/g,
          '_'
        )}/${this.photobook.package_order.product.name}/${
          this.photobook.photobook_id
        }`
      : // tslint:disable-next-line:max-line-length
        `${environment.dropboxPath}/${dateString}/(CS-${csName})-${
          this.photobook.package_order.order.order_code
        }-${this.photobook.package_order.order.customer.name.replace(
          /\s/g,
          '_'
        )}/${this.photobook.package_order.product.name}/${
          this.photobook.title
        }-${this.photobook.photobook_id}`;
    return this.dropboxPaymentPath;
  }

  createForm(): any {
    this.photobookForm = new FormGroup({
      id: new FormControl(this.photobook.photobook_id),
      photos: new FormArray([]),
      desc: new FormControl(null),
    });
  }

  async getCoverPhoto() {
    if (this.coverPhoto) {
      try {
        this.coverPhotoUploaded = true;
        const imageCover = await this.dropboxService
          .getThumbnail({
            path: this.coverPhoto.db_path,
            size: 'w128h128',
            format: 'jpeg',
            mode: 'strict',
          })
          .toPromise();
        this.coverPhoto.thumbnail = imageCover;
        this.coverPhoto.error = null;
        this.coverPhoto.thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(imageCover)
        );
      } catch (error) {
        this.coverPhoto.thumbnail = null;
        this.coverPhoto.error = error.statusText;
      }
    }
  }

  mapThumbnailToPhoto(images: any[]) {
    this.photos.map((photo) => {
      for (const image of images) {
        if (photo.db_file_id === image.metadata.id) {
          photo.thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(this.dataURItoBlob(image.thumbnail))
          );
          return photo;
        }
      }
    });
  }

  getParams(params: any[]) {
    return {
      entries: params,
    };
  }

  async mapOneByOne(photoErrorAssumed) {
    try {
      const image = await this.dropboxService
        .getThumbnail({
          path: photoErrorAssumed.path,
          size: 'w128h128',
          format: 'jpeg',
          mode: 'strict',
        })
        .toPromise();
      this.photos.map((photoToUpdate) => {
        if (photoToUpdate.db_file_id === photoErrorAssumed.path) {
          photoToUpdate.thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(image)
          );
          return photoToUpdate;
        }
      });
    } catch (error) {
      this.photos.map((photoToUpdate) => {
        if (photoToUpdate.db_file_id === photoErrorAssumed.path) {
          photoToUpdate.error = 'Foto tidak bisa dimuat ';
          return photoToUpdate;
        }
      });
    }
  }

  async getPhotosBatch() {
    this.loading = true;
    this.createForm();
    const photos = await this.uploadService
      .getPhotoByPhotobook(this.photobook.photobook_id)
      .pipe(
        map((data) => {
          return data.map((photo) => {
            photo.isUploaded = true;
            photo.captionWarning = null;
            if (photo.caption) {
              photo.isCaption = true;
            } else {
              photo.isCaption = false;
            }
            photo.checked = false;
            return photo;
          });
        })
      )
      .toPromise();
    this.coverPhoto = photos.filter(
      (photo) => photo.db_path.indexOf('custome-cover') !== -1
    )[0];
    this.photos = photos.filter(
      (photo) => photo.db_path.indexOf('custome-cover') === -1
    );
    await this.getCoverPhoto();
    this.orderPhoto();
    let photosParam = [];
    for (const photo of this.photos) {
      photosParam.push({
        path: photo.db_file_id,
        format: { '.tag': 'jpeg' },
        size: { '.tag': 'w128h128' },
        mode: { '.tag': 'strict' },
      });
      if (photosParam.length === 10) {
        try {
          const images = await this.dropboxService
            .getThumbnailBatch(this.getParams(photosParam))
            .toPromise();
          this.mapThumbnailToPhoto(images.entries);
        } catch (error) {
          for (const photoErrorAssumed of photosParam) {
            this.mapOneByOne(photoErrorAssumed);
          }
        }
        photosParam = [];
      }
    }
    try {
      const sisaImages = await this.dropboxService
        .getThumbnailBatch(this.getParams(photosParam))
        .toPromise();
      this.mapThumbnailToPhoto(sisaImages.entries);
    } catch (error) {
      for (const photoErrorAssumed of photosParam) {
        this.mapOneByOne(photoErrorAssumed);
      }
    }
    this.loading = false;
    this.isUploadedDone();
    this.dropboxPath = this.getPath();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  async getPhotos() {
    this.loading = true;
    this.createForm();
    this.allPhotos = await this.uploadService
      .getPhotoByPhotobook(this.photobook.photobook_id)
      .pipe(
        map((data) => {
          return data.map((photo) => {
            photo.isUploaded = true;
            if (photo.caption) {
              photo.isCaption = true;
            } else {
              photo.isCaption = false;
            }
            return photo;
          });
        })
      )
      .toPromise();
    this.coverPhoto = this.allPhotos.filter(
      (photo) => photo.db_path.indexOf('custome-cover') !== -1
    )[0];
    this.photos = this.allPhotos.filter(
      (photo) => photo.db_path.indexOf('custome-cover') === -1
    );
    await this.getCoverPhoto();
    this.orderPhoto();
    for (const photo of this.photos) {
      try {
        const image = await this.dropboxService
          .getThumbnail({
            path: photo.db_file_id,
            size: 'w128h128',
            format: 'jpeg',
            mode: 'strict',
          })
          .toPromise();
        photo.thumbnail = image;
        photo.error = null;
        photo.thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(image)
        );
      } catch (error) {
        photo.thumbnail = null;
        photo.error = error.statusText;
      } finally {
        photo.checked = false;
      }
    }
    this.loading = false;
    this.isUploadedDone();
    this.dropboxPath = this.getPath();
  }

  onAddCoverPhoto(event) {
    this.coverPhotoUploaded = true;
  }

  insertPhotoToForm(photo: any) {
    const photoForm = new FormGroup({
      id: new FormControl(photo.id),
      order: new FormControl(photo.order),
      name: new FormControl(photo.name),
      caption: new FormControl(photo.caption),
    });
    (<FormArray>this.photobookForm.get('photos')).push(photoForm);
  }

  onSaveOrder() {
    const photos = [];
    const captionsPhotos = [];
    for (const photo of this.photos) {
      if (photo.caption && photo.order !== 1000) {
        captionsPhotos.push(`${photo.order} - ${photo.caption}`);
      }
      if (photo.is_order === 1) {
        photos.push({
          id: photo.id,
          order: photo.order,
          is_order: photo.is_order,
          name: photo.name,
          caption: photo.caption,
        });
      } else {
        photos.push({
          id: photo.id,
          order: photo.order,
          name: photo.name,
          caption: photo.caption,
        });
      }
    }
    this.uploadService
      .saveOrderPhoto({
        photos: photos,
        notes: captionsPhotos.join(', '),
        photobook_id: this.photobook.photobook_id,
      })
      .subscribe((response) => {
        this.isToSaveOrder = false;
      });
  }

  onClickCaption(event, index) {
    const photosWithCaption = this.photos.filter(
      (photo) => photo.isCaption === true
    );
    if (photosWithCaption.length < this.max_caption) {
      this.photos[index].isCaption = true;
      // setTimeout(() => this.focusInput.nativeElement.focus());
    } else {
      this.showWarning(
        `Jumlah foto yang menggunakan caption maksimal ${this.max_caption} foto`
      );
    }
  }

  onErrorImageLoad(event: any) {
    event.target.src = this.defaultImage;
  }

  onChangeCaption(event: any, index: number) {
    const captionPattern = /^[\x20-\x7F]+$/;
    let temp = event.target.value;
    if (temp.length === 40) {
      this.photos[index].captionWarning = 'Maksimal caption 40 karakter';
    } else if (!temp.match(captionPattern)) {
      temp = this.removeEmojis(temp);
    } else {
      this.photos[index].captionWarning = null;
    }

    this.photos[index].caption = temp;
    this.isToSaveOrder = true;
    // this.isCaptionCompleted();
  }

  isCaptionCompleted() {
    const photos = this.photos;
    const photoWithCaption = photos.filter((photo) => {
      if (photo.caption !== null && photo.caption !== '') {
        return photo;
      }
    });
    if (photoWithCaption.length > 2) {
      this.store.dispatch(new AppActions.SetCaption(false));
    } else {
      this.store.dispatch(new AppActions.SetCaption(true));
    }
  }

  isNotDoubleInput(files) {
    for (let index = 0; index < files.length - 1; index++) {
      const first = files[index];
      for (let jindex = index + 1; jindex < files.length; jindex++) {
        const second = files[jindex];
        if (first.name === second.name) {
          return false;
        }
      }
    }
    return true;
  }

  onUploadInputChange(event) {
    const totalPhotos = event.target.files.length + this.photos.length;
    if (event.target.files.length <= 20) {
      if (this.maxPhoto && totalPhotos <= this.maxPhoto) {
        const inputed = [];
        this.photosToSave = [];
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          if (!inputed.includes(file.name)) {
            const newPhoto = {
              id: null,
              order: 1000,
              thumbnail: null,
              db_path: null,
              isUploaded: null,
              loading: null,
              favorited: false,
              extension: null,
              progress: 0,
              error: false,
              is_order: 0,
              file,
              name: file.name,
              checked: false,
              caption: null,
            };
            this.photosToSave = [...this.photos, newPhoto];
            this.photos = [...this.photos, newPhoto];
            inputed.push(file.name);
          }
        }
        this.uploadOneByOne();
      } else {
        this.showWarning('Jumlah Foto yang kakak pilih kelebihan');
      }
    } else {
      this.showWarning('Maksimal Foto untuk sekali upload adalah 20');
    }
  }

  showWarning(message: string) {
    const snackBarRef = this.snackBar.open(message, 'Tutup', {
      duration: 5000,
    });
    snackBarRef.afterDismissed().subscribe(() => {
      console.log('The snack-bar was dismissed');
    });
  }

  async uploadOneByOne() {
    this.parentLoading = true;
    this.loading = true;
    for (let i = 0; i < this.photosToSave.length; i++) {
      const photo = this.photosToSave[i];
      try {
        if (photo.isUploaded) {
          continue;
        }
        const uploadArgs = {
          contents: photo.file,
          path: `${this.dropboxPath}/1000-${new Date().getTime()}-${
            photo.file.name
          }`,
        };
        this.photos[i].loading = true;
        const result = await this.uploadOne(uploadArgs, i);
        this.photosToSave[i] = (
          await this.uploadService
            .create({
              photobook_id: this.photobook.photobook_id,
              db_path: result.path_lower,
              db_file_id: result.id,
              order: 1000,
              is_order: photo.is_order,
              extension: this.getExtension(photo.file.name),
              mime_type: photo.file.type,
              name: photo.file.name,
            })
            .pipe(
              map((response) => {
                response.data.isUploaded = true;
                return response;
              })
            )
            .toPromise()
        ).data;

        const image = await this.dropboxService
          .getThumbnail({
            path: this.photosToSave[i].db_path,
            size: 'w128h128',
            format: 'jpeg',
            mode: 'strict',
          })
          .toPromise();
        this.photosToSave[
          i
        ].thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(image)
        );
        this.photosToSave[i].isCaption = false;
        this.photosToSave[i].caption = null;
        this.photosToSave[i].loading = false;
        this.photosToSave[i].isUploaded = true;
        this.photosToSave[i].order = 1000;
        this.photosToSave[i].file = null;
        this.photos = [...this.photosToSave];
        this.isUploadedDone();
      } catch (error) {
        this.photos[i].loading = false;
        this.photos[i].error = error.statusText;
      }
    }
    this.parentLoading = false;
    this.loading = false;
    // this.uploadInput.nativeElement.value = '';
  }

  getExtension(string) {
    const regexAll = /[^\\]*\.(\w+)$/;
    const total = string.match(regexAll);
    return total[1] ? total[1] : '.jpg';
  }

  async uploadOne(arg, i) {
    try {
      const data = await this.dropboxService
        .filesUpload(
          {
            path: arg.path,
            mode: 'add',
            autorename: true,
            mute: false,
            strict_conflict: false,
          },
          arg.contents
        )
        .pipe(
          retryWhen(unlimitedRetry([429])),
          map((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round(
                (100 * event.loaded) / event.total
              );
              this.photos[i].progress = percentDone;
            } else if (event instanceof HttpResponse) {
              if (event.status === 200) {
                return event.body;
              }
              throw event;
            }
          })
        )
        .toPromise();
      return data;
    } catch (error) {
      throw error;
    }
  }

  isRemove() {
    const checkPhotos = this.photos.filter((photo) => photo.checked === true);
    if (checkPhotos.length > 0) {
      this.checkPhoto = true;
    } else {
      this.checkPhoto = false;
    }
  }

  onChangeCheckbox(event: any, i: number) {
    this.photos[i].checked = event.checked;
    if (!event.checked) {
      this.checkAll = false;
    }
    this.isRemove();
  }

  onRemoveCaption(index: number) {
    this.photos[index].isCaption = false;
    this.photos[index].caption = null;
    this.photos[index].is_order = 1;
    this.isToSaveOrder = true;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async onSelectChange(event: any, index) {
    await this.delay(0);
    const replicatePhotos = [...this.photos];
    const orderVictimNow = replicatePhotos[index].order;
    replicatePhotos[index].order = event.value;
    replicatePhotos[index].is_order = 1;

    if (
      replicatePhotos[index].order === 1000 ||
      replicatePhotos[index].order === '1000'
    ) {
      replicatePhotos[index].caption = null;
      replicatePhotos[index].isCaption = false;
    }
    const photosWithOrderArray = replicatePhotos.filter(
      (photo) =>
        photo.order === event.value && photo.id !== this.photos[index].id
    );
    const photosWithOrder = photosWithOrderArray[0];

    if (photosWithOrder) {
      if (photosWithOrder.order !== 1000) {
        const indexNumberVictims = replicatePhotos.indexOf(photosWithOrder);
        replicatePhotos[indexNumberVictims].order = orderVictimNow;
        replicatePhotos[indexNumberVictims].is_order = 1;
        if (
          replicatePhotos[indexNumberVictims].order === 1000 ||
          replicatePhotos[indexNumberVictims].order === '1000'
        ) {
          replicatePhotos[indexNumberVictims].caption = null;
          replicatePhotos[indexNumberVictims].isCaption = false;
        }
      }
    }

    this.photos = [...replicatePhotos.sort((a, b) => a.order - b.order)];
    this.isToSaveOrder = true;
  }

  orderPhoto() {
    const newPhotosOrder = [...this.photos];
    for (let i = 0; i < newPhotosOrder.length - 1; i++) {
      for (let j = i + 1; j < newPhotosOrder.length; j++) {
        if (newPhotosOrder[i].order > newPhotosOrder[j].order) {
          const temp = { ...newPhotosOrder[i] };
          newPhotosOrder[i] = { ...newPhotosOrder[j] };
          newPhotosOrder[j] = { ...temp };
        }
      }
    }
    this.photos = [...newPhotosOrder];
  }

  sortArray(arr: any[]) {
    for (let i = arr.length; i--; ) {
      const orderValue = arr[i].order - 1;
      if (orderValue > arr.length) {
        arr[i].order = 1000;
      } else if (orderValue && orderValue !== 999 && orderValue !== i) {
        this.swapArray(arr, i, orderValue);
      }
    }
  }

  swapArray(arr, indexA, indexB) {
    const temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  }

  async updatePhoto(photo: any) {
    const thumbnail = photo.thumbnail;
    photo = await this.uploadService.update(photo).toPromise();
    photo.thumbnail = thumbnail;
    photo.isUploaded = true;
    return photo;
  }

  async renameOrderOnDropbox(source, sourcePath, order) {
    try {
      return (
        await this.dropboxService
          .filesMove({
            from_path: sourcePath,
            to_path: `${this.getPath()}/${order}-${source.name}`,
            autorename: true,
          })
          .toPromise()
      ).metadata;
    } catch (error) {
      if (error.status === 429) {
        return await this.renameOrderOnDropbox(source, sourcePath, order);
      }
    }
  }

  onChangeCheckAll(event: any) {
    this.checkAll = event.checked;
    this.photos.forEach((photo) => (photo.checked = event.checked));
    if (!event.checked) {
      this.checkAll = false;
      this.checkPhoto = false;
    }
  }

  async onDelete() {
    this.loading = true;
    const photos = this.photos.filter((item) => item.checked);
    const deletedPhotos = [...this.photos];
    this.parentLoading = true;
    for (const photo of photos) {
      const index = deletedPhotos.indexOf(photo);
      photo.loading = true;
      try {
        await this.deleteOnDropbox(photo.db_path);
        await this.uploadService.delete(photo).toPromise();
        deletedPhotos.splice(index, 1);
        this.checkAll = false;
        this.photos = [...deletedPhotos];
        this.isUploadedDone();
      } catch (error) {
        photo.loading = false;
      } finally {
        this.parentLoading = false;
      }
    }
    this.loading = false;
    this.checkAll = false;
    deletedPhotos.forEach((photo) => {
      photo.checked = false;
    });

    this.isRemove();
    this.onSaveOrder();
  }

  async deleteOnDropbox(path) {
    try {
      return await this.dropboxService.filesDelete({ path }).toPromise();
    } catch (error) {
      if (error.status === 429) {
        return await this.deleteOnDropbox(path);
      }
    }
  }

  removeEmojis(string) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, '');
  }
}
