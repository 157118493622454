<div class="photobook" fxLayout="row" fxLayoutAlign="flex-start start">
  <div class="block" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <div class="order" fxLayout="column" fxLayoutAlign="center center">
      <div
        class="order__product"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Produk</span>
        <span>{{ photobook?.package_order?.product?.name }}</span>
      </div>
      <div
        class="order__title"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Judul Album</span>
        <span>{{ photobook?.title }}</span>
      </div>

      <div
        class="order__layout"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Layout</span>
        <span>{{ photobook?.layout?.name }}</span>
      </div>

      <div
        class="order__photo"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Foto yang harus diupload</span>
        <span>{{ photobook?.package_order?.product?.max_photo }}</span>
      </div>

      <div
        class="order__photo"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Foto terupload</span>
        <span>{{ photos?.length }}</span>
      </div>
      <div
        *ngIf="customeCover"
        class="order__photo"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Cover foto terupload</span>
        <span *ngIf="coverPhotoUploaded">Sudah</span>
        <span class="cover-warn" *ngIf="!coverPhotoUploaded">Belum</span>
      </div>
    </div>
  </div>
  <!-- sample flex -->

  <h4 *ngIf="customeCover">Upload Cover</h4>
  <app-cover-photo
    [photobook]="photobook"
    *ngIf="customeCover"
    (coverPhotoReport)="onAddCoverPhoto($event)"
    [coverPhoto]="coverPhoto"
  ></app-cover-photo>

  <div
    *ngIf="photos?.length > 0"
    class="check"
    fxFlex="100%"
    fxFlex.xs="100%"
    fxFlex.sm="100%"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <span class="check" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <button
        [disabled]="(!checkPhoto && !checkAll) || loading"
        (click)="onDelete()"
        mat-raised-button
        color="warn"
      >
        Hapus Terpilih
      </button>
    </span>
  </div>
  <div
    class="column"
    fxFlex="100%"
    fxFlex.xs="100%"
    fxFlex.sm="100%"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <span class="check" fxFlex="10%" fxFlex.xs="10%" fxFlex.sm="10%">
      <mat-checkbox
        *ngIf="photos?.length > 0"
        [checked]="checkAll"
        (change)="onChangeCheckAll($event)"
      ></mat-checkbox>
    </span>
    <span fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%">Photo</span>
    <span>Urutan</span>
    <span>Progress</span>
  </div>
</div>
<div class="list">
  <cdk-virtual-scroll-viewport style="height: 500px" itemSize="100">
    <ng-container
      *cdkVirtualFor="let photo of photos; let i = index; templateCacheSize: 0"
    >
      <!-- <app-photo (photoOrder)="onChangeOrder($event)" (removeCaption)="onRemoveCaption($event)" (photoCaption)="onChangeCaption($event)" 
      [index]="i" (isCheckPhoto)="onChangeCheckPhoto($event)" [photo]="photo" [photos]="photos"></app-photo> -->

      <!-- photo -->
      <div class="container" fxLayout="column" fxLayoutAlign="start start">
        <div
          class="block"
          fxFlex="100%"
          fxFlex.xs="100%"
          fxFlex.sm="100%"
          fxLayout="row"
          fxLayoutAlign="space-between start"
        >
          <div
            class="block__number"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="5%"
            fxFlex.xs="5%"
            fxFlex.sm="5%"
          >
            {{ i + 1 }}
          </div>
          <div
            class="block__check"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="55%"
            fxFlex.xs="55%"
            fxFlex.sm="55%"
          >
            <mat-checkbox
              class="box"
              fxLayoutAlign="start start"
              [checked]="photo.checked"
              (change)="onChangeCheckbox($event, i)"
            >
              <div class="image" fxLayoutAlign="start start" fxLayout="column">
                <img
                  (error)="onErrorImageLoad($event)"
                  [src]="photo.thumbnail"
                  alt=""
                />
                <span class="error" *ngIf="photo.error"
                  >{{ photo.error }} -</span
                >
              </div>
            </mat-checkbox>
            <span
              class="caption-button"
              *ngIf="
                isAlbum &&
                max_caption > 0 &&
                photo.order !== 1000 &&
                photo.order !== '1000'
              "
              (click)="onClickCaption($event, i)"
              >Caption</span
            >
          </div>
          <div
            class="block__order"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="20%"
            fxFlex.xs="20%"
            fxFlex.sm="20%"
          >
            <mat-select
              class="select"
              [disabled]="photo.loading"
              placeholder="Urutan"
              [value]="photo.order"
              (selectionChange)="onSelectChange($event, i)"
            >
              <mat-option [value]="1000"> tidak diurutkan </mat-option>
              <mat-option
                [value]="j + 1"
                *ngFor="let photo2 of photos; let j = index"
              >
                {{ j + 1 }}
              </mat-option>
            </mat-select>
          </div>
          <div
            class="block__progress"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxFlex="15%"
            fxFlex.xs="15%"
            fxFlex.sm="15%"
          >
            <span *ngIf="!photo.isUploaded"> {{ photo.progress }}% </span>
            <span *ngIf="photo.isUploaded && !photo.loading">
              <i class="material-icons"> done </i>
            </span>
            <span *ngIf="photo.loading">
              <mat-spinner [diameter]="30" class="spinner"></mat-spinner>
            </span>
          </div>
        </div>
        <span class="error-photo" *ngIf="photo.error"
          >Silahkan hubungi cs support kami atau hapus kemudian upload kembali
        </span>
        <div
          *ngIf="
            (photo.order !== '1000' &&
              photo.isCaption &&
              photo.order !== 1000) ||
            (photo.caption !== null && photo.caption !== '')
          "
          class="caption"
          fxLayoutGap="4px"
          fxLayout="row"
          fxLayoutAlign="start start"
        >
          <input
            class="caption-text"
            maxlength="40"
            matInput
            placeholder=""
            (keyup)="onChangeCaption($event, i)"
            [value]="photo.caption"
            fxFlex="70%"
            fxFlex.xs="70%"
            fxFlex.sm="70%"
            type="text"
            required
          />
          <span>
            <i
              class="material-icons remove"
              (click)="onRemoveCaption(i)"
              style="color: red"
            >
              delete
            </i>
          </span>
        </div>
        <span class="warning-caption" *ngIf="photo.captionWarning"
          >* {{ photo?.captionWarning }}</span
        >
      </div>
      <!-- photo -->
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
<div class="uploader" fxLayout="column" fxLayoutAlign="space-between start">
  <!-- <button *ngIf="isToSaveOrder"  mat-raised-button color="default"  class="upload-button" (click)="onSaveOrder()">
        Simpan Urutan Gambar dan Caption
    </button>
  <button *ngIf="!($uploaded | async)" [disabled]="parentLoading || loading" (click)="uploadInput.click()" mat-raised-button color="accent" class="upload-button">
      Upload Gambar
  </button> -->

  <input
    class="upload"
    type="file"
    #uploadInput
    accept="image/*"
    multiple="true"
    (change)="onUploadInputChange($event)"
  />
</div>

<div class="container-footer" fxLayout="row" fxLayoutAlign="center end">
  <div
    class="checkout"
    fxFlex="35%"
    fxFlex.xs="100%"
    fxFlex.sm="100%"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <button
      fxFlex="100%"
      *ngIf="isToSaveOrder && !loading"
      fxFlex.xs="100%"
      fxFlex.sm="100%"
      fxLayout="row"
      fxLayoutAlign="center start"
      (click)="onSaveOrder()"
      class="button--save"
      mat-raised-button
      color="primary"
    >
      SIMPAN URUTAN
    </button>

    <button
      fxFlex="100%"
      fxFlex.xs="100%"
      (click)="uploadInput.click()"
      *ngIf="!isToSaveOrder && photos?.length < maxPhoto && !loading"
      fxFlex.sm="100%"
      fxLayout="row"
      fxLayoutAlign="center start"
      class="button--upload"
      mat-raised-button
      color="primary"
    >
      UPLOAD GAMBAR
    </button>

    <button
      fxFlex="100%"
      fxFlex.xs="100%"
      *ngIf="loading"
      fxFlex.sm="100%"
      fxLayout="row"
      fxLayoutAlign="center start"
      mat-raised-button
      class="button--loading"
    >
      <mat-spinner
        class="spinner__box"
        color="warn"
        [diameter]="20"
      ></mat-spinner>
    </button>

    <button
      fxFlex="100%"
      fxFlex.xs="100%"
      *ngIf="!isToSaveOrder && photos?.length >= maxPhoto && !loading"
      fxFlex.sm="100%"
      fxLayout="row"
      fxLayoutAlign="center start"
      (click)="onClickClose()"
      class="button--close"
      mat-raised-button
      color="primary"
    >
      TUTUP
    </button>
  </div>
</div>
