import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule, Store } from '@ngrx/store';
import { appReducer } from './reducers/app.reducer';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ColorResolver } from './resolvers/color.resolver';
import { LayoutResolver } from './resolvers/layout.resolver';
import { OrderResolver } from './resolvers/order.resolver';
import { photobookReducer } from './reducers/photobook.reducer';
import { AuthService } from './auth/auth.service';
import { CoreService } from './core/core.service';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({
      appStore: appReducer,
      photobookStore: photobookReducer,
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Store],
    },
    ColorResolver,
    LayoutResolver,
    OrderResolver,
    AuthService,
    CoreService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
