// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://alpha.photostory.id:3001',
  afterUploadUrl: 'https://staging.kirimfoto.photostory.id/#/home',
  dropboxPath: '/testing_real',
  dropboxKey:
    'yJ5_8RVoZAAAAAAAACbj7elYY0dUwo9OdqN-0jPYL0CG4l5LpI3BAiiKHNNrWld_',
  surveyFormUrl:
    'https://docs.google.com/forms/d/e/1FAIpQLSfAD4Mo_bb-tvQm1qZBRgvR0SgxNFA_JDnyhg_DxpKNfUa3yA/viewform?embedded=true',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
