import { Action } from '@ngrx/store';
import { environment } from './../../environments/environment';
export const SET_TITLE = 'SET_TITLE';
export const SET_ORDER = 'SET_ORDER';
export const SET_ACTION_BUTTON = 'SET_ACTION_BUTTON';
export const SET_ACTION_LINK = 'SET_ACTION_LINK';
export const SET_LOADING = 'SET_LOADING';
export const SET_UPLOADED = 'SET_UPLOADED';
export const SET_REMOVE_PHOTO = 'SET_REMOVE_PHOTO';
export const SET_CAPTION = 'SET_CAPTION';
export const SET_ERROR = 'SET_ERROR';



export class SetTitle implements Action {
    readonly type = SET_TITLE;
    constructor(public payload: string) {}
}


export class SetOrder implements Action {
    readonly type = SET_ORDER;
    constructor(public payload: number) {
    }
}

export class SetActionLink implements Action {
    readonly type = SET_ACTION_LINK;
    constructor(public payload: string) {
    }
}

export class SetActionButton implements Action {
    readonly type = SET_ACTION_BUTTON;
    constructor(public payload: string) {
    }
}

export class Setloading implements Action {
    readonly type = SET_LOADING;
    constructor(public payload: boolean) {
    }
}

export class SetUploaded implements Action {
    readonly type = SET_UPLOADED;
    constructor(public payload: boolean) {
    }
}

export class SetRemovePhoto implements Action {
    readonly type = SET_REMOVE_PHOTO;
    constructor(public payload: boolean) {
    }
}

export class SetCaption implements Action {
    readonly type = SET_CAPTION;
    constructor(public payload: boolean) {
    }
}

export class SetError implements Action {
    readonly type = SET_ERROR;
    constructor(public payload: string) {
    }
}



export type AppActions = SetTitle |  SetOrder | SetActionButton | SetActionLink |
Setloading | SetUploaded | SetRemovePhoto | SetCaption | SetError;
