import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmblemService } from './emblem.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as AppActions from 'src/app/actions/app.action';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import { CoreService } from '../core.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-emblem',
  templateUrl: './emblem.component.html',
  styleUrls: ['./emblem.component.scss'],
})
export class EmblemComponent implements OnInit, AfterViewInit, OnDestroy {
  photobook: any;
  product: any;
  emblems: any;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emblemService: EmblemService,
    private coreService: CoreService,
    private store: Store<{ appStore: AppState }>
  ) {
    this.store.dispatch(new AppActions.SetTitle('Pilih Emblem'));
  }

  ngOnInit() {
    this.photobook = this.route.snapshot.data['photobook'];
    this.product = this.photobook.package_order.product;
    // console.log(this.photobook)
  }

 


  ngAfterViewInit() {
    this.subscription = this.emblemService
      .getEmblem()
      .subscribe((data) => {
        this.emblems = data;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onChooseTheme(emblem) {
    const photobook = {
      id: this.photobook.photobook_id,
      emblem_id: emblem.id,
      layout_id: this.photobook.layout_id,
      cover_color_id: this.photobook.cover_color_id,
      title: this.photobook.title,
    };
    this.coreService.updatePhotobook(photobook).subscribe((data) => {
      this.router.navigate(['/home']);
    });
  }
}
