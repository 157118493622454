import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { dropboxContentUrl, dropboxApiUrl } from './../../shared/constants';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DropboxService {

  constructor(private httpClient: HttpClient) {}

  getThumbnail(params) {
    // console.log(params)
    const httpOptions: {
      headers?: HttpHeaders;
      responseType: 'blob';
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.dropboxKey}`,
        'Dropbox-API-Arg': JSON.stringify(params)
      }),

      responseType: 'blob'
    };
    return this.httpClient.post(
      `${dropboxContentUrl}/files/get_thumbnail`,
      null,
      httpOptions
    ).pipe(
      retry(10),
      map (response => response),
      catchError(this._errorHandler)
    );
  }

  _errorHandler(error: Response) {
    return throwError(error || 'Server Error');
  }

  getPreview(file_id) {
    // console.log(file_id)
    const httpOptions: {
      headers?: HttpHeaders,
      // responseType:"blob"
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.dropboxKey}`,
        'Content-Type': 'application/json'
        // "Dropbox-API-Arg": JSON.stringify({path:file_id})
      }),

      // responseType: "blob"
    };
    return this.httpClient.post(`${dropboxApiUrl}/files/get_temporary_link`, {path: file_id}, httpOptions);
  }

  getThumbnailBatch(params): any {
    // console.log(params)
    const httpOptions: {
      headers?: HttpHeaders;
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.dropboxKey}`,
        'Content-Type': 'application/json'
      }),
    };
    return this.httpClient.post(
      `${dropboxContentUrl}/files/get_thumbnail_batch`,
      params,
      httpOptions
    ).pipe(
      retry(10),
      map (response => response),
      catchError(this._errorHandler)
    );
  }

  filesUpload(params, file): any {
    // console.log(file)
    const httpOptions: {
      headers?: HttpHeaders;
      observe: 'events';
      reportProgress: true;
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.dropboxKey}`,
        'Dropbox-API-Arg': JSON.stringify(params),
        'Content-Type': 'application/octet-stream'
      }),
      observe: 'events',
      reportProgress: true
    };
    return this.httpClient.post(
      `${dropboxContentUrl}/files/upload`,
      file,
      httpOptions
    ).pipe(
      catchError(this._errorHandler)
    );
  }

  filesMove(params): Observable<any> {
    const httpOptions: {
      headers?: HttpHeaders;
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.dropboxKey}`
      })
    };
    return this.httpClient.post(
      `${dropboxApiUrl}/files/move_v2`,
      params,
      httpOptions
    );
  }

  filesDelete(params) {
    const httpOptions: {
      headers?: HttpHeaders;
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.dropboxKey}`
      })
    };
    return this.httpClient.post(
      `${dropboxApiUrl}/files/delete_v2`,
      params,
      httpOptions
    );
  }
}
