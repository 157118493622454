import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  AfterViewInit,
  Output,
} from '@angular/core';
import { shortMonths, months } from './../../../shared/constants';
import { environment } from './../../../../environments/environment';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import * as AppActions from 'src/app/actions/app.action';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { UploadService } from '../upload.service';
import { DropboxService } from '../dropbox.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from 'src/app/reducers/app.reducer';

@Component({
  selector: 'app-cover-photo',
  templateUrl: './cover-photo.component.html',
  styleUrls: ['./cover-photo.component.scss'],
})
export class CoverPhotoComponent implements OnInit, AfterViewInit {
  @Input() coverPhoto: any;
  @Input() photobook: any;
  dropboxPathCover: any = null;
  @ViewChild('uploadCover', { static: true })
  uploadCover: ElementRef;
  loading = null;
  @Output()
  coverPhotoReport = new EventEmitter();

  constructor(
    private store: Store<{ appStore: AppState }>,
    private uploadService: UploadService,
    private dropboxService: DropboxService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  getPathCover() {
    const date = new Date(this.photobook.package_order.order.created_at);
    const dateString = `${date.getDate()}-${
      months[date.getMonth()]
    }-${date.getFullYear()}`;
    // tslint:disable-next-line:max-line-length
    const csName = this.photobook.package_order.order.customer_service
      ? this.photobook.package_order.order.customer_service.full_name
      : 'selfservice';
    // tslint:disable-next-line:max-line-length
    const path = `${environment.dropboxPath}/${dateString}/(CS-${csName})-${
      this.photobook.package_order.order.order_code
    }-${this.photobook.package_order.order.customer.name.replace(/\s/g, '_')}/${
      this.photobook.package_order.product.name
    }`;
    return path;
  }

  onClickCover() {
    this.uploadCover.nativeElement.click();
  }

  async onUploadInputCover(event) {
    this.loading = true;
    await this.onDelete();
    this.dropboxPathCover = this.getPathCover();
    const file = event.target.files[0];
    this.coverPhoto = {
      id: null,
      order: 1000,
      thumbnail: null,
      db_path: null,
      isUploaded: null,
      loading: null,
      favorited: false,
      extension: null,
      progress: 0,
      error: false,
      file,
      name: file.name,
      checked: false,
    };
    const uploadArgs = {
      contents: this.coverPhoto.file,
      path: `${this.dropboxPathCover}/1000-${new Date().getTime()}-${
        this.photobook.photobook_id
      }-custome-cover-${this.coverPhoto.file.name}`,
    };
    const result = await this.uploadCoverPhoto(uploadArgs);
    this.coverPhoto = (
      await this.uploadService
        .create({
          photobook_id: this.photobook.photobook_id,
          db_path: result.path_lower,
          db_file_id: result.id,
          order: 1000,
          extension: this.getExtension(result.name),
          mime_type: result.type,
          name: result.name,
        })
        .pipe(
          map((response) => {
            response.data.isUploaded = true;
            return response;
          })
        )
        .toPromise()
    ).data;
    const image = await this.dropboxService
      .getThumbnail({
        path: this.coverPhoto.db_path,
        size: 'w128h128',
        format: 'jpeg',
        mode: 'strict',
      })
      .toPromise();
    this.coverPhoto.thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(image)
    );
    this.coverPhotoReport.emit({
      photobook_id: this.photobook.photobook_id,
      db_path: result.path_lower,
      db_file_id: result.id,
      order: 1000,
      extension: this.getExtension(result.name),
      mime_type: result.type,
      name: result.name,
    });
    this.loading = false;
    this.coverPhoto.loading = false;
    this.coverPhoto.isUploaded = true;
  }

  async uploadCoverPhoto(arg) {
    try {
      return await this.dropboxService
        .filesUpload(
          {
            path: arg.path,
            mode: 'add',
            autorename: true,
            mute: false,
            strict_conflict: false,
          },
          arg.contents
        )
        .pipe(
          map((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round(
                (100 * event.loaded) / event.total
              );
              this.coverPhoto.progress = percentDone;
            } else if (event instanceof HttpResponse) {
              if (event.status === 200) {
                return event.body;
              }
              throw event;
            }
          })
        )
        .toPromise();
    } catch (error) {
      throw error;
      //   this.coverPhoto.error = true;
      // if (error.status === 429) {
      //   return await this.uploadCoverPhoto(arg);
      // } else {
      //   this.coverPhoto.error = true;
      // }
    }
  }

  getExtension(string) {
    const regexAll = /[^\\]*\.(\w+)$/;
    const total = string.match(regexAll);
    return total[1] ? total[1] : '.jpg';
  }

  async onDelete() {
    try {
      await this.deleteOnDropbox(this.coverPhoto.db_path);
      await this.uploadService.delete(this.coverPhoto).toPromise();
    } catch (error) {
      console.log(error);
    } finally {
      console.log('done');
    }
  }

  async deleteOnDropbox(path) {
    try {
      return await this.dropboxService.filesDelete({ path }).toPromise();
    } catch (error) {
      if (error.status === 429) {
        return await this.deleteOnDropbox(path);
      }
    }
  }
}
