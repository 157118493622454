import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'indoDate'
})

export class IndoDatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return moment(value).locale('id').format('Do MMM YYYY');
  }
}
