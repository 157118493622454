export const days: string[] = [
  'Minggu',
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  'Jumat',
  'Sabtu',
];
export const months: string[] = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const shortMonths: string[] = [
  'jan',
  'feb',
  'mar',
  'apr',
  'mei',
  'jun',
  'jul',
  'agst',
  'sept',
  'okt',
  'nov',
  'des',
];
export const dropboxApiUrl = 'https://api.dropboxapi.com/2';
export const dropboxContentUrl = 'https://content.dropboxapi.com/2';
export const defaultStatus: any[] = [
  {
    ids: [1, 2],
    title: 'Pemesanan',
    icon: 'shopping_cart',
  },
  {
    ids: [3, 4],
    title: 'Verifikasi Foto',
    icon: 'image_search',
  },
  {
    ids: [5],
    title: 'Foto Lengkap',
    icon: 'playlist_add_check',
  },
  {
    ids: [6, 7, 8],
    title: 'Proses Layout',
    icon: 'web',
  },
  {
    ids: [9, 10, 11, 12, 13, 14],
    title: 'Proses Produksi',
    icon: 'build',
  },
  {
    ids: [16],
    title: 'Pengiriman',
    icon: 'local_shipping',
  },
  {
    ids: [17, 18, 19],
    title: 'Diterima',
    icon: 'check',
  },
];

export const statusGroups = [
  {
    value: [1, 2, 3, 4, 21],
    text: 'FOTO BELUM TERVERIFIKASI',
  },
  {
    value: [5, 6, 7, 8],
    text: 'SEDANG LAYOUT',
  },
  {
    value: [22],
    text: 'PREVIEW CUSTOMER',
  },
  {
    value: [9, 10, 23, 24, 25, 11, 12, 27, 28, 29, 30, 13, 14, 15],
    text: 'SEDANG PRODUKSI',
  },
  {
    value: [16, 26],
    text: 'ORDER SEDANG DIKIRIM',
  },
  {
    value: [17],
    text: 'COMPLAINT',
  },
  {
    value: [18],
    text: 'REFUND',
  },
  {
    value: [19],
    text: 'ORDER SELESAI',
  },
];

export const getStatusGroup = (statusId) =>
  statusGroups.find((el) => el.value.includes(statusId)).text;
