<div class="container" fxLayout="row" fxLayoutAlign="flex-start start">
  <div class="block" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <div class="order" fxLayout="column" fxLayoutAlign="center center">
      <div
        class="order__code"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Customer</span>
        <span>{{ order.customer.name }}</span>
      </div>
      <div
        class="order__code"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Kode Order</span>
        <span>{{ order.order_code }}</span>
      </div>
      <div
        class="order__create"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Tanggal Pesan</span>
        <span>{{ order.created_at | indoDate }}</span>
      </div>

      <!-- <div
        class="order__status"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <span>Status Order</span>
        <span>{{
          order.package_orders[0].photobooks[0].currentStatus.status
        }}</span>
      </div> -->
    </div>
    <div class="logout">
      Bukan order yang dimaksud ? <a (click)="onLogout()">Cari Order lain</a>
    </div>
    <div class="rules">
      <h4>Tata cara pengiriman foto</h4>
      Tata cara pengiriman foto dapat dibaca
      <span (click)="onClickRule()" class="rule">di sini</span>
    </div>
    <div
      class="container-form"
      *ngIf="
        order?.package_orders[0]?.photobooks[0]?.current_status == 4 ||
        order?.package_orders[0]?.photobooks[0]?.current_status < 3
      "
    >
      <form [formGroup]="PackageForm" (submit)="onFormSubmit()">
        <div
          class="package-order"
          fxLayout="column"
          formArrayName="package_order"
        >
          <div
            fxLayout="column"
            *ngFor="let packageOrder of packageOrders.controls; let i = index"
            formGroupName="{{ i }}"
          >
            <div
              fxLayout="column"
              class="photobooks"
              formArrayName="photobooks"
            >
              <div
                fxLayout="column"
                class="photobook"
                *ngFor="
                  let photobook of packageOrder.get('photobooks').controls;
                  let j = index
                "
                formGroupName="{{ j }}"
                fxLayoutAlign="start start"
              >
                <h3>Album {{ order?.package_orders[i].product.name }}</h3>

                <ng-template #noUpload>
                  <ng-container
                    *ngTemplateOutlet="
                      noUploadImage;
                      context: { product: order?.package_orders[i].product }
                    "
                  >
                  </ng-container>
                </ng-template>

                <div
                  *ngIf="
                    order.package_orders[i].product.max_photo !== 0 &&
                      order?.package_orders[i].product.max_photo !== null;
                    else noUpload
                  "
                  class="wrap-album"
                >
                  <mat-form-field
                    *ngIf="order.package_orders[i].product.is_album"
                    class="test photobook__formControl"
                  >
                    <input
                      maxlength="50"
                      matInput
                      placeholder="Tulis judul album kakak"
                      formControlName="title"
                    />
                    <mat-error
                      *ngIf="
                        photobook.get('title').hasError('required') &&
                        (photobook.get('title').dirty ||
                          photobook.get('title').touched)
                      "
                    >
                      Judul harus diisi</mat-error
                    >
                    <mat-error
                      *ngIf="
                        photobook.get('title').hasError('pattern') &&
                        (photobook.get('title').dirty ||
                          photobook.get('title').touched)
                      "
                    >
                      Karakter tidak didukung</mat-error
                    >
                  </mat-form-field>
                  <span
                    class="error"
                    *ngIf="photobook.get('title').value?.length > 49"
                    >Maksimal 50 Karakter</span
                  >
                  <div
                    class="cover-theme"
                    *ngIf="order.package_orders[i].product.is_album"
                  >
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <div
                        *ngIf="order.package_orders[i].product.is_with_cover"
                        class="cover-theme__cover"
                        fxLayout="column"
                        fxLayoutAlign="start center"
                        fxFlex="50%"
                        fxFlex.xs="50%"
                        fxFlex.sm="50%"
                      >
                        <h4>
                          {{
                            order?.package_orders[i].photobooks[j].cover_color
                              ?.name || 'COVER'
                          }}
                        </h4>
                        <img
                          [defaultImage]="'assets/loading.gif'"
                          [lazyLoad]="
                            order?.package_orders[i].photobooks[j].cover_color
                              ?.image_preview_1
                          "
                          *ngIf="
                            order.package_orders[i].photobooks[j].cover_color;
                            else noImage
                          "
                          alt=""
                        />
                      </div>
                      <div
                        *ngIf="order.package_orders[i].product.emblem"
                        class="cover-theme__cover"
                        fxLayout="column"
                        fxLayoutAlign="start center"
                        fxFlex="50%"
                        fxFlex.xs="50%"
                        fxFlex.sm="50%"
                      >
                        <h4>
                          {{
                            order?.package_orders[i].photobooks[j].emblem
                              ?.name || 'EMBLEM'
                          }}
                        </h4>
                        <img
                          [defaultImage]="'assets/loading.gif'"
                          [lazyLoad]="
                            order?.package_orders[i].photobooks[j].emblem?.link
                          "
                          *ngIf="
                            order.package_orders[i].photobooks[j].emblem;
                            else noImage
                          "
                          alt=""
                        />
                      </div>
                      <div
                        class="cover-theme__theme"
                        fxLayout="column"
                        fxLayoutAlign="start center"
                        fxFlex="50%"
                        fxFlex.xs="50%"
                        fxFlex.sm="50%"
                      >
                        <h4>
                          {{
                            order?.package_orders[i].photobooks[j].layout
                              ?.name || 'LAYOUT'
                          }}
                        </h4>
                        <div class="frame">
                          <iframe
                            class="frame"
                            *ngIf="
                              order.package_orders[i].photobooks[j].layout &&
                                order.package_orders[i].photobooks[j].layout
                                  .html_file;
                              else noImage
                            "
                            [src]="
                              sanitize(
                                order.package_orders[i].photobooks[j].layout
                                  .html_file
                              )
                            "
                            style="border: none; width: 100%"
                            class="embed-responsive-item iframe-custom"
                            allowfullscreen
                          >
                          </iframe>
                        </div>
                      </div>
                    </div>
                    <div
                      class="button"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    >
                      <span
                        *ngIf="
                          order.package_orders[i].product.is_with_cover &&
                          !hasUpload
                        "
                        fxLayout="column"
                        fxLayoutAlign="center center"
                        fxFlex="50%"
                        fxFlex.xs="50%"
                        fxFlex.sm="50%"
                      >
                        <a
                          mat-raised-button
                          color="accent"
                          (click)="onChangeCover(photobook.value)"
                          >Pilih Cover</a
                        >
                      </span>
                      <span
                        *ngIf="order.package_orders[i].product.emblem"
                        fxLayout="column"
                        fxLayoutAlign="center center"
                        fxFlex="50%"
                        fxFlex.xs="50%"
                        fxFlex.sm="50%"
                      >
                        <a
                          mat-raised-button
                          color="accent"
                          (click)="onChangeEmblem(photobook.value)"
                          >Pilih Emblem</a
                        >
                      </span>
                      <span
                        fxLayout="column"
                        fxLayoutAlign="center center"
                        fxFlex="50%"
                        fxFlex.xs="50%"
                        fxFlex.sm="50%"
                      >
                        <a
                          mat-raised-button
                          color="accent"
                          (click)="onChangeTheme(photobook.value)"
                          >Pilih Layout</a
                        >
                      </span>
                    </div>
                  </div>

                  <div class="progress" fxLayout="column">
                    <mat-progress-bar
                      [color]="
                        isCompletePhoto(
                          order?.package_orders[i].photobooks[j].uploaded,
                          order.package_orders[i].product.max_photo
                        )
                      "
                      mode="determinate"
                      [value]="
                        order.package_orders[i].photobooks[j].photos.length
                          | percentage
                            : order.package_orders[i].product.max_photo
                      "
                    >
                    </mat-progress-bar>
                    <span class="uploaded"
                      >Terupload
                      {{ order?.package_orders[i].photobooks[j].uploaded }} dari
                      {{ order.package_orders[i].product.max_photo }} yang harus
                      diupload</span
                    >
                  </div>
                  <div class="upload">
                    <a
                      mat-raised-button
                      fxFlex="100%"
                      fxFlex.xs="100%"
                      fxFlex.sm="100%"
                      (click)="onUpload(photobook.value, i, j)"
                    >
                      Upload Photo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="error">
          <ul class="messages">
            <li *ngFor="let error of errors">{{ error?.message }}</li>
          </ul>
        </div>
        <!-- <button (click)="onUploadtest()">test</button> -->
        <div class="submit" fxLayout="row" fxLayoutAlign="center start">
          <button
            [disabled]="!PackageForm.valid || isLoadingSubmit"
            mat-raised-button
            color="primary"
            fxFlex="100%"
            fxFlex.xs="100%"
            fxFlex.sm="100%"
          >
            Simpan
          </button>
        </div>
      </form>
    </div>
    <div
      *ngIf="
        order?.package_orders[0]?.photobooks[0]?.current_status !== 4 &&
        order?.package_orders[0]?.photobooks[0]?.current_status >= 3
      "
      class="container-photobook"
      fxLayout="column"
      fxLayoutAlign="center start"
    >
      <div
        *ngFor="let packageOrder of order.package_orders; let i = index"
        class="block"
        fxLayout="column"
        fxLayoutAlign="center start"
        fxFlex="100%"
        fxFlex.xs="100%"
        fxFlex.sm="100%"
      >
        <div
          *ngFor="let photobook of packageOrder.photobooks; let j = index"
          class="block__photobook"
          fxLayout="column"
          fxLayoutAlign="center start"
          fxFlex="100%"
          fxFlex.xs="100%"
          fxFlex.sm="100%"
        >
          <h4>Album {{ packageOrder.product.name }} - {{ photobook.title }}</h4>
          <div
            class="status"
            fxLayout="row"
            fxLayoutAlign="space-between start"
          >
            <span class="status__date"> Tanggal </span>
            <span class="status__now"> Status Sekarang </span>
          </div>
          <div
            *ngFor="let status of photobook.statusGrouping; let j = index"
            class="status"
            fxLayout="row"
            fxLayoutAlign="space-between start"
          >
            <span class="status__date">
              {{ status.created_at | indoDate }}
            </span>
            <span class="status__now">
              {{ status.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noImage>
  <div class="default">
    <img src="assets/no-image.png" alt="" />
  </div>
</ng-template>

<ng-template #noImageProduct>
  <div class="default-product-image">
    <img src="assets/no-image.png" alt="" />
  </div>
</ng-template>

<ng-template #noUploadImage let-product="product">
  <div>
    <!-- <img *ngIf="product.image_preview; else noImageProduct" [src]="product.image_preview" alt=""> -->
    <span>Upload foto tidak tersedia untuk produk ini</span>
  </div>
</ng-template>
