import { Observable } from 'rxjs';
import { pipe, zip, range, throwError, timer } from 'rxjs';
import { retryWhen, mergeMap } from 'rxjs/operators';

const checkRetry = ({
  error,
  retryAttempt,
  maxRetry,
  errorWhiteList,
}): boolean => {
  return (
    errorWhiteList.includes(parseInt(error.status)) && maxRetry > retryAttempt
  );
};

export function backoffDelay(maxRetry, errorWhiteList) {
  return pipe(
    retryWhen((attempts) =>
      zip(range(1, maxRetry + 1), attempts).pipe(
        mergeMap(([i, err]) => {
          const isRetry = checkRetry({
            error: err,
            retryAttempt: i,
            maxRetry,
            errorWhiteList,
          });
          //default delay 10s * retry attemp
          const delay =
            err.error && err.error.error && err.error.error.retry_after
              ? err.error.error.retry_after * 1000
              : i * 10 * 1000;
          return isRetry ? timer(delay) : throwError(err);
        })
      )
    )
  );
}

export const unlimitedRetry = (errorWhiteList: number[] = []) => (
  attempts: Observable<any>
) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      if (!errorWhiteList.includes(error.status)) throwError(error);
      const delay =
        error.error && error.error.error && error.error.error.retry_after
          ? error.error.error.retry_after * 1000
          : i * 10 * 1000;
      return timer(delay);
    })
  );
};
