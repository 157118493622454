import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, shareReplay, tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Injectable()
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  signIn(loginObj: { order_id: string; phone: string }) {
    return this.httpClient
      .post<any>(environment.apiUrl + '/uploaders/login', loginObj)
      .pipe(
        shareReplay(),
        tap((response) => {
          console.log('ini response: ', response);
          this.saveLocalStorage(response);
        }),
        map((response) => response),
        catchError(this.errorHandler)
      );
  }

  getExpiration() {
    const expiration = sessionStorage.getItem('expire');
    const expiresAt = JSON.parse(expiration);
    return expiresAt;
  }

  isTokenExpired() {
    const token = sessionStorage.getItem('token');
    if (token) {
      const expire = helper.isTokenExpired(token);
      return expire;
    } else {
      return false;
    }
  }

  saveLocalStorageFromDashboard(token) {
    sessionStorage.setItem('token', JSON.stringify(token));
    const decoded = helper.decodeToken(token);
    sessionStorage.setItem('user', JSON.stringify(decoded.sub.full_name));
    // console.log(decoded)
  }

  getExpireDate() {
    const token = sessionStorage.getItem('token');
    if (token) {
      const expire = helper.getTokenExpirationDate(token);
      return expire;
    } else {
      return true;
    }
  }

  removAuth() {
    sessionStorage.clear();
  }

  clearPackageProductlocalStorage() {
    sessionStorage.removeItem('packages');
    sessionStorage.removeItem('products');
  }

  errorHandler(response: any) {
    return throwError(
      response.error ? response.error.message : response.error || 'Server Error'
    );
  }

  saveLocalStorage(response: any) {
    sessionStorage.setItem('order_id', JSON.stringify(response.data.order_id));
    sessionStorage.setItem('token', JSON.stringify(response.meta.token));
  }
}
