import * as AppActions from './../actions/app.action';

export interface AppState {
    title: string;
    order: number;
    actionButton: string;
    linkAction: string;
    loading: boolean;
    uploaded: boolean;
    isRemovePhoto: boolean;
    isWithCaption: boolean;
    error: string;
}


const initialState: AppState = {
    title: '',
    order: null,
    actionButton: null,
    linkAction: null,
    loading: false,
    uploaded: false,
    isRemovePhoto: false,
    isWithCaption: true,
    error: null

};

export function appReducer(
    state: AppState = initialState,
    action: AppActions.AppActions
) {
    switch (action.type) {
        case AppActions.SET_TITLE:
            return { ...state, title: action.payload };
        case AppActions.SET_ORDER:
            return { ...state, order: action.payload };
        case AppActions.SET_ACTION_BUTTON:
            return { ...state, actionButton: action.payload };
        case AppActions.SET_ACTION_LINK:
            return { ...state, linkAction: action.payload };
        case AppActions.SET_LOADING:
            return { ...state, loading: action.payload };
        case AppActions.SET_UPLOADED:
            return { ...state, uploaded: action.payload };
        case AppActions.SET_REMOVE_PHOTO:
            return { ...state, isRemovePhoto: action.payload };
        case AppActions.SET_CAPTION:
                return { ...state, isWithCaption: action.payload };
        default:
            return state;
    }

}
