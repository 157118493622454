import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BodyComponent } from './core/body/body.component';
import { HomeComponent } from './core/home/home.component';
import { OrderResolver } from './resolvers/order.resolver';
import { CoversComponent } from './core/covers/covers.component';
import { LayoutsComponent } from './core/layouts/layouts.component';
import { EmblemComponent } from './core/emblem/emblem.component';
import { PhotobookResolver } from './resolvers/photobook.resolver';
import { UploadComponent } from './core/upload/upload.component';
import { AuthGuardService } from './auth/auth.guard.service';
import { RulesComponent } from './core/rules/rules.component';

const routes: Routes = [
  {
    path: '',
    component: BodyComponent,
    children: [
      {
        path: '',
        redirectTo: '/rules',
        pathMatch: 'full',
      },
      {
        path: 'rules',
        component: RulesComponent
      },
      {
        path: 'home',
        component: HomeComponent,
        resolve: {
          order: OrderResolver
        }
      },
      {
        path: 'covers/:photobook_id',
        component: CoversComponent,
        resolve: {
          photobook: PhotobookResolver
        }
      },
      {
        path: 'layouts/:photobook_id',
        component: LayoutsComponent,
        resolve: {
          photobook: PhotobookResolver
        }
      },
      {
        path: 'emblem/:photobook_id',
        component: EmblemComponent,
        resolve: {
          photobook: PhotobookResolver
        }
      },
      {
        path: 'upload/:photobook_id',
        component: UploadComponent,
        resolve: {
          photobook: PhotobookResolver
        }
      }
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'signin',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
