import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'percentage'
})

export class PercentagePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value && args[0]) {
      const percentageProgress = (value / args[0]) * 100;
      return percentageProgress;
    } else {
      return 0;
    }

  }
}
