import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CoreService } from '../core/core.service';

@Injectable()
export class OrderResolver implements Resolve<any> {
  constructor(
    private coreService: CoreService,
    private authService: AuthService,
    private router: Router
  ) {}
  resolve() {
    const orderId = JSON.parse(sessionStorage.getItem('order_id'));
    if (orderId) {
      return this.coreService.getOrderById(orderId);
    } else {
      this.router.navigate(['/signin']);
    }
  }
}
