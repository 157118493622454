import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, pluck, catchError } from 'rxjs/operators';
import { Photobook } from '../interface/photobook';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  error = new BehaviorSubject(null);
  dataError = this.error.asObservable();
  constructor(private httpClient: HttpClient) {}

  getError() {
    return this.dataError;
  }

  setError(message) {
    return this.error.next(message);
  }

  getOrderById(id: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/orders/' + id).pipe(
      map((response) => response),
      pluck('data'),
      catchError(this._errorHandler)
    );
  }

  getPhotobookById(id: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/photobooks/' + id).pipe(
      map((response) => response),
      pluck('data'),
      catchError(this._errorHandler)
    );
  }

  _errorHandler(error: Response) {
    return throwError(error || 'Server Error');
  }

  updatePhotobook(photobook: any): Observable<Photobook> {
    return this.httpClient
      .put(environment.apiUrl + '/photobooks/', photobook)
      .pipe(
        map((response) => response),
        pluck('data')
      );
  }

  updatePhotobooks(photobook: any): Observable<Photobook> {
    return this.httpClient
      .put(environment.apiUrl + '/photobooks/photobooks', photobook)
      .pipe(
        map((response) => response),
        pluck('data')
      );
  }

  forwardFlow(data: any) {
    return this.httpClient
      .post(environment.apiUrl + '/flows/forward', data)
      .pipe(
        map((response) => response),
        pluck('data')
      );
  }

  update(order) {
    return this.httpClient
      .put(environment.apiUrl + '/uploaders/order', order)
      .pipe(
        map((response) => response),
        pluck('data')
      );
  }

  sendNotif(order_code) {
    return this.httpClient
      .post(environment.apiUrl + '/uploaders/notif', { order_code })
      .pipe(
        map((response) => response),
        pluck('data')
      );
  }
}
