<div class="photobook" fxLayout="row"  fxLayoutAlign="flex-start start">

<div class="album" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <div class="order" fxLayout="column" fxLayoutAlign="center center">
        <div class="order__code" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <span>Produk</span>
            <span>{{ photobook?.package_order?.product?.name }}</span>
        </div>
        <div class="order__create" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <span>Judul Album</span>
            <span>{{ photobook?.title }}</span>
        </div>

        <div class="order__status" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
            <span>Layout</span>
            <span>{{ photobook?.layout?.name }}</span>
        </div>
    </div>
    </div>
  <div class="block" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let cover of covers;">
      <h4>{{ cover?.name }}</h4>
      <div class="block__image" fxLayout="column" fxLayoutAlign="center start" >
          <img [defaultImage]="'assets/loading.gif'" [lazyLoad]="cover.image_preview_1" *ngIf="cover.image_preview_1; else noImage"  alt="">
      </div>
      <div class="photobook__button">
          <button mat-raised-button color="accent"  fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" (click)="onChooseTheme(cover)">
              Pilih
          </button>
      </div>
  </div>


</div>

<ng-template #noImage>
    <div class="default">
        <img src="assets/no-image.png" alt="">
    </div>
</ng-template>