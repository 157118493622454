import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndoDatePipe } from './pipes/indo-date.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { SelectOrderPipe } from './pipes/select-order.pipe';

@NgModule({
  declarations: [
    IndoDatePipe,
    PercentagePipe,
    SelectOrderPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IndoDatePipe,
    PercentagePipe,
    SelectOrderPipe
  ]
})
export class SharedModule { }
