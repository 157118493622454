import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private httpClient: HttpClient) { }

  getPhotoByPhotobook(photobookId: number): Observable<any> {
    return this.httpClient.get(environment.apiUrl + '/photos/photobook/' + photobookId)
      .pipe(
        map(response => response),
        pluck('data')
      );
  }

  create(photo): Observable<any> {
    // console.log(photo);
    return this.httpClient.post(`${environment.apiUrl}/photos/create`, photo);
  }

  saveOrderPhoto(photos: any): Observable<any> {
    // console.log(photo);
    return this.httpClient.post(`${environment.apiUrl}/photos/save-order`, photos);
  }

  delete(photo) {
    return this.httpClient.delete(`${environment.apiUrl}/photos/${photo.id}`);
  }

  update(photo) {
    return this.httpClient.put(`${environment.apiUrl}/photos`, photo)
    .pipe(
      map(response => response),
      pluck('data')
    );
  }
}
