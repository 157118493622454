<h3>Tata Cara Pengiriman Foto </h3>
<div class="rules" >
  <ol class="rule">
    <li> 
      Silakan tuliskan judul album. Perhatikan setiap karakter seperti pemenggalan kata, huruf, kapital, tanda baca dan sebagainya. Penggunaan emoticon tidak disarankan.
    </li>
    <li>
      Jika tidak menghendaki adanya judul, silakan tuliskan "TANPA JUDUL" di kolom judul.
    </li>
    <li>
      Untuk memilih desain cover, silakan klik tombol Pilih di kolom cover. Untuk memilih desain layout album, silakan klik tombol Pilih di kolom Layout.
    </li>
    <li>
      Proses upload foto
      <ul>
        <li>
          Jika menghendaki foto di layout secara urut, silakan gunakan fitur urutan foto
        </li>
        <li>
          Rekomendasi ukuran file foto minimal 500kb atau 300 dpi untuk menghindari foto pecah dan hasil cetak tidak maksimal
        </li>
        <li>
          Penambahan fitur persetujuan sebelum login kirim foto
        </li>
        <li>
          Jika ada foto yang salah, silakan hapus foto
        </li>
        <li>
          Untuk proses upload foto dalam jumlah yang banyak disarankan melalui komputer dan menggunakan browser chrome atau mozilla
        </li>
      </ul>
    </li>
    <li>
      Pastikan seluruh foto, judul, caption, pilihan cover dan desain sudah sesuai
    </li>
    <li>
      Jika foto sudah diurutkan pada nama file, maka jangan mengurutkan kembali ketika upload foto
    </li>
    <li>
      Setelah klik upload foto, sistem langsung memproses file sehingga tidak bisa direvisi atau dilakukan perubahan
    </li>
    <li>
      Jika ada kesalahan maupun kesulitan dalam pengiriman foto (sebelum klik simpan) silakan hubungi nomor wa CS Support <span class="phone">+62 858-6866-3005</span> 
    </li>
    <li>
      Setelah klik button Simpan, dan proses upload berhasil silakan hubungi Nomor WA CS Support untuk konfirmasi
    </li>
  </ol>
  <h3>Tata cara penulisan Caption :</h3> 
  <ol class="captions">
    <li>Maksimal 40 karakter per caption</li>
    <li>Berikan nomor urut ketika proses upload foto</li>
    <li>Klik button caption</li>
    <li>Tulis caption beserta urutan caption (untuk mengetahui caption tersebut ditulis pada urutan ke berapa)</li>
    <li>Klik simpan</li>
  </ol>
  <div class="note">
    *NB : Harap ikuti tata cara diatas. Pastikan seluruh file sudah benar untuk menghindari proses pembuatan album yang tidak maksimal. Klaim garansi hanya diberikan jika proses upload foto sesuai dengan tata cara pengiriman foto.
  </div>
  <div class="button-upload">
    <button mat-raised-button color="accent"  fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" (click)="onUpload()">
      Mulai upload
    </button>
  </div>
</div>
