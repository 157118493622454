import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutsService } from './layouts.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import * as AppActions from 'src/app/actions/app.action';
import { CoreService } from '../core.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent implements OnInit, AfterViewInit, OnDestroy {
  photobook: any;
  product: any;
  layouts: any;
  layoutPreview: any = [];
  test: SafeResourceUrl = null;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private layoutService: LayoutsService,
    private router: Router,
    private coreService: CoreService,
    private store: Store<{ appStore: AppState }>
  ) {
    this.store.dispatch(new AppActions.SetTitle('Pilih Tema'));
   }

  ngOnInit() {
    this.photobook = this.route.snapshot.data['photobook'];
    this.product = this.photobook.package_order.product;
  }


  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngAfterViewInit() {
    this.subscription = this.layoutService.getLayouts(this.product.id)
      .subscribe(data => {
        this.layouts = data;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onChooseTheme(layout: any) {
    const photobook = {
      id: this.photobook.photobook_id,
      layout_id: layout.id,
      cover_color_id: this.photobook.cover_color_id,
      emblem_id: this.photobook.emblem_id,
      title: this.photobook.title
    };
    this.coreService.updatePhotobook(photobook)
      .subscribe(data => {
          this.router.navigate(['/home']);
      });
  }

}
