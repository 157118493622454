<header class="header" fxLayout="row" fxLayoutAlign="center start">
  <div class="heading" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="35%" fxFlex.xs="100%" fxFlex.sm="70%">
      <!-- <div class="heading__logo" *ngIf="$title | async"> -->
      <div fxLayout="row" fxLayoutAlign="flex-start start">
          <div *ngIf="$title | async" class="heading__logo">         
              <button mat-icon-button (click)="goBack()">
                  <mat-icon>arrow_back</mat-icon>
              </button>
          </div>
          <div class="heading__title" >
              <span class="title" *ngIf="$title | async; else default">
                {{ $title | async }}
              </span>

              <!-- <img *ngIf="!($title | async)" src="assets/logo.png" alt=""> -->
          </div>
      </div>
      <!-- <div class="done" (click)="onClickDone()" *ngIf="$uploaded | async" class="heading__upload" fxLayout="column" fxLayoutAlign="center center">
         <span >
            <i class="material-icons">
              done
              </i>
         </span>
          <span>Selesai</span>
      </div> -->
  </div>
</header>
<div class="spinner" *ngIf="$loading | async">
    <mat-spinner class="spinner__box"></mat-spinner>
</div>
<main>
  <section class="section" fxFlex="35%" fxFlex.xs="100%" fxFlex.sm="70%" fxLayout="column" fxLayoutAlign="flex-start start">
      <router-outlet></router-outlet>
  </section>
</main>



<ng-template #default>
  <span class="title-default">
    Kirim Foto
  </span>
</ng-template>
