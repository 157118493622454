<div class="custome" fxLayout="column" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
  <div class="custome__image" fxLayout="row" fxLayoutAlign="center start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <img *ngIf="coverPhoto?.thumbnail; else noImage" [src]="coverPhoto.thumbnail" alt="">
  </div>
  <span fxLayout="row" fxLayoutAlign="center start" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
    <!-- {{ coverPhoto?.progress }} -->
  </span>
  <input class="upload" type="file" #uploadCover accept="image/*" (change)="onUploadInputCover($event)">
  <div fxLayoutGap="5px" class="custome__upload" fxLayout="row" fxLayoutAlign="center end" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
      <button (click)="onClickCover()" mat-raised-button color="accent">
          Upload
      </button>
      <mat-spinner *ngIf="loading" [diameter]="30" class="spinner"></mat-spinner>

  </div>
</div>

<ng-template #noImage>
    <div class="default">
      <img src="assets/no-image.png" alt="">
    </div>
</ng-template>