import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoversService } from './covers.service';
import * as AppActions from 'src/app/actions/app.action';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import { CoreService } from '../core.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-covers',
  templateUrl: './covers.component.html',
  styleUrls: ['./covers.component.scss'],
})
export class CoversComponent implements OnInit, AfterViewInit, OnDestroy {
  photobook: any;
  product: any;
  covers: any;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coverService: CoversService,
    private coreService: CoreService,
    private store: Store<{ appStore: AppState }>
  ) {
    this.store.dispatch(new AppActions.SetTitle('Pilih Cover'));
  }

  ngOnInit() {
    this.photobook = this.route.snapshot.data['photobook'];
    this.product = this.photobook.package_order.product;
    // console.log(this.photobook)
  }

  ngAfterViewInit() {
    this.subscription = this.coverService
      .getCovers(this.product.id)
      .subscribe((data) => {
        this.covers = data;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onChooseTheme(cover) {
    const photobook = {
      id: this.photobook.photobook_id,
      cover_color_id: cover.id,
      layout_id: this.photobook.layout_id,
      emblem_id: this.photobook.emblem_id,
      title: this.photobook.title,
    };
    this.coreService.updatePhotobook(photobook).subscribe((data) => {
      this.router.navigate(['/home']);
    });
  }
}
