import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyComponent } from './body/body.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { CoreService } from './core.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CoversComponent } from './covers/covers.component';
import { CoversService } from './covers/covers.service';
import { LayoutsComponent } from './layouts/layouts.component';
import { LayoutsService } from './layouts/layouts.service';
import { EmblemComponent } from './emblem/emblem.component';
import { EmblemService } from './emblem/emblem.service';
import { PhotobookResolver } from '../resolvers/photobook.resolver';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UploadComponent } from './upload/upload.component';
import { UploadService } from './upload/upload.service';
import { PhotoComponent } from './upload/photo/photo.component';
import { DropboxService } from './upload/dropbox.service';
import { CoverPhotoComponent } from './upload/cover-photo/cover-photo.component';
import { RulesComponent } from './rules/rules.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FooterComponent } from './body/footer/footer.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { SurveyComponent } from './survey/survey.component';

@NgModule({
  declarations: [
    BodyComponent,
    HomeComponent,
    CoversComponent,
    LayoutsComponent,
    EmblemComponent,
    UploadComponent,
    PhotoComponent,
    CoverPhotoComponent,
    RulesComponent,
    FooterComponent,
    AlertDialogComponent,
    SurveyComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ScrollingModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    LazyLoadImageModule
  ],
  providers: [
    CoreService,
    CoversService,
    LayoutsService,
    EmblemService,
    PhotobookResolver,
    UploadService,
    DropboxService,
  ],
  entryComponents: [SurveyComponent]
})
export class CoreModule { }
